<template>
    <div class="seongdong_curation_wrap peroid" :class="getCurationCss">
        <ul class="setting_target_detail">
            <h2 class="curation_body_list_title"> 선호 시대 </h2>
            <ul class="curation_list">
                <li class="curation_list_item mb" v-for="item in getPeroid" :key="item.id">
                    <label :for="item.id" class="curation_item peroid" :class="peroid.includes(item.value) ? 'active' : ''">{{
                        item.koText
                    }}s</label>
                    <input :id="item.id" type="checkbox" hidden :value="item.value" v-model="peroid">
                </li>

            </ul>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'SeongDongCurationPeorid',
  emits: ['send-curation'],
  props: {
    peroidarray: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      peroid: this.peroidarray.map(item => this.$store.getters["player/getCurationStorePeroid"](item))
    };
  },

  mounted () {

  },

  methods: {

  },
  computed: {
    getCurationCss () {
      return this.$store.getters.gettersCurationCssClass;
    },
    getPeroid () {
      return this.$store.getters['curation/getPeroid'];
    }
  },
  watch: {
    peroid: {
      deep: true,
      handler (val) {
        this.$emit('send-curation', {
          type: 'peroid',
          value: val
        });
      }
    }
  }
};
</script>
<style scoped src="@/assets/css/seongdonglibary/seongdongcuration.css"></style>
