import { render, staticRenderFns } from "./SeongDongCurationPeorid.vue?vue&type=template&id=9d0a019e&scoped=true"
import script from "./SeongDongCurationPeorid.vue?vue&type=script&lang=js"
export * from "./SeongDongCurationPeorid.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/seongdonglibary/seongdongcuration.css?vue&type=style&index=0&id=9d0a019e&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d0a019e",
  null
  
)

export default component.exports